import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Modal, Pagination } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { SpinnerDotted } from 'spinners-react';
import Cookies from 'universal-cookie';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import Edit from './edit';
import { TableContext } from '../../contexts/tableContext';
import {
  REACT_APP_API_DELETE_KNOWLEDGE_BASE_DETAILS,
  REACT_APP_API_FETCH_KNOWLEDGE_BASE,
  REACT_APP_API_POST_KNOWLEDGE_BASE,
} from '../../redux/apiConstants';
import styles from './index.module.scss';

function index() {
  const { dashboardStyle, bodyStyle, setDashboardHeader } = useContext(TableContext);
  const navigate = useNavigate();
  const cookies = new Cookies();
  const userid = cookies.get('USERID');
  const username = cookies.get('USERNAME');
  // pagination filter search
  const [obj, setobj] = useState([]);
  const [page, setpage] = useState(1);
  const [search, setsearch] = useState('');
  const [limit, setlimit] = useState();
  const [total, settotal] = useState();
  const [spinner, setspinner] = useState(false);
  const alldatas = async () => {
    setspinner(true);
    try {
      const url = `${REACT_APP_API_FETCH_KNOWLEDGE_BASE}page=${page}&search=${search}`;
      const data = await axios.get(url);
      console.log('hhh', data);
      setobj(data.data.data.results.questions);
      setlimit(data.data.data.results.limit);
      settotal(data.data.data.results.total);
      setspinner(false);
    } catch (error) {
      console.log(error);
      setspinner(false);
    }
  };
  useEffect(() => {
    setDashboardHeader('Knowledge Base');
    alldatas();
  }, [search, page]);

  const totalPages = Math.ceil(total / limit);

  console.log(totalPages);
  const setPage = (indexx) => {
    setpage(indexx + 1);
    window.scrollTo({ top: 0, left: 0 });
  };

  // pagination end
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const formSchema = Yup.object().shape({
    question: Yup.string().required(' Question is mendatory'),
    answer: Yup.string().required(' Answer is mendatory'),
  });

  const formOptions = { resolver: yupResolver(formSchema) };
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    trigger,
  } = useForm(formOptions);

  const onSubmit = async (res) => {
    const data = {
      question: res.question,
      answer: res.answer,
      userId: userid,
      userName: username,
    };
    console.log(data);
    reset();

    try {
      const response = await axios.post(`${REACT_APP_API_POST_KNOWLEDGE_BASE}`, data);
      console.log('res', response);
      handleClose();
      toast(response.data.context, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      reset();
      alldatas();
    } catch (error) {
      console.log('err', error);
      //   toast(error.response.data.message, {
      //     position: 'top-right',
      //     autoClose: 5000,
      //     hideProgressBar: false,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     draggable: true,
      //     progress: undefined,
      //     theme: 'dark',
      //   });
    }
  };

  const ticketDetails = (id) => {
    navigate('/knowledge-base-details', {
      state: {
        knowledge_base_id: id,
      },
    });
  };

  // delete

  const Delete = async (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await axios.delete(
            `${REACT_APP_API_DELETE_KNOWLEDGE_BASE_DETAILS}/${id}`
          );
          console.log(response);
          Swal.fire('Deleted!', response.data.context, 'success');
          alldatas();
        } catch (error) {
          console.log(error);
        }
      }
    });
  };
  return (
    <div className="container">
      <div className={styles[bodyStyle]}>
        <div
          className={styles[dashboardStyle]}
          style={{ height: '100vh' }}
          id={styles.dashboardcont2}
        >
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
          {/* Same as */}
          <ToastContainer />
          {/* <h3>knowledgeBase</h3> */}

          <div className="form-outline mb-4">
            <div className="d-flex justify-content-between">
              {/* <label className="form-label" htmlFor="datatable-search-input">
              Search
            </label> */}
              <input
                placeholder="search"
                type="search"
                className="form-control"
                id="datatable-search-input"
                onChange={(e) => setsearch(e.target.value)}
              />
              <button className="btn btn-primary ms-5" type="submit" onClick={handleShow}>
                Add
              </button>
            </div>
          </div>
          <Modal show={show} onHide={handleClose}>
            <form className="comment-form" method="post" onSubmit={handleSubmit(onSubmit)}>
              <Modal.Header closeButton>
                <Modal.Title>Create </Modal.Title>
              </Modal.Header>
              <div className="comment-respond container">
                <p className="comment-form-url">
                  <label htmlFor="question">Question</label>
                </p>
                <input
                  type="text"
                  id="question"
                  name="question"
                  className="form-control"
                  required
                  /* eslint-disable react/jsx-props-no-spreading */
                  {...register('question')}
                  onKeyUp={() => {
                    trigger('question');
                  }}
                />
                {errors.question && (
                  <small style={{ color: 'red' }}>{errors.question.message}</small>
                )}
                <p className="comment-form-answer">
                  <label htmlFor="answer">Answer</label>
                </p>

                <textarea
                  className="form-control"
                  name="answer"
                  rows="4"
                  cols="50"
                  required
                  {...register('answer')}
                  onKeyUp={() => {
                    trigger('answer');
                  }}
                />
                {errors.answer && <small style={{ color: 'red' }}>{errors.answer.message}</small>}
              </div>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
                <Button variant="primary" type="submit" onClick={handleSubmit(onSubmit)}>
                  Save Changes
                </Button>
              </Modal.Footer>
            </form>
          </Modal>
          {spinner ? (
            <div>
              <SpinnerDotted
                style={{ marginLeft: '400px', marginTop: '200px', color: '#39979d' }}
              />
              <div style={{ marginLeft: '400px', marginTop: '300px' }} />
            </div>
          ) : (
            obj.map((data) => (
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between">
                    <p> </p>
                    <span>
                      <Edit datas={alldatas} source={data._id} />
                      <button
                        className={styles.actionbtn}
                        type="button"
                        onClick={() => Delete(data._id)}
                      >
                        <i className="fa-solid fa-trash" />
                      </button>
                    </span>
                  </div>
                  <h5 className="card-title"> {data.question}</h5>
                  <p
                    style={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                    className="card-text"
                  >
                    {data.answer}
                  </p>
                  <button
                    type="button"
                    /* eslint no-underscore-dangle: 0 */
                    onClick={() => ticketDetails(data._id)}
                    className="btn btn-primary"
                  >
                    Read More
                  </button>
                </div>
              </div>
            ))
          )}
          {totalPages > 0 && (
            <div className="container mt-5 mb-5 d-flex align-items-center justify-content-center">
              <Pagination size="sm">
                {[...Array(totalPages)].map((val, number) => (
                  <Pagination.Item onClick={() => setPage(number)} active={number + 1 === page}>
                    {number + 1}
                  </Pagination.Item>
                ))}
              </Pagination>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default index;
