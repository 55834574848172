/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes, { bool } from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Button, ButtonGroup } from 'react-bootstrap';
import axios from 'axios';
import { TableContext } from '../../contexts/tableContext';
// import { SpinnerRoundFilled } from 'spinners-react';
import ActionButtons from '../ActionsButtons/ActionButtons';
import Pagination from '../Pagination/Pagination';
import { tabledelete } from '../../redux/TableDeleteSlice';
import ToggleButtonOnOff from '../ToggleButton/ToggleButton';
// import ActionSelection from '../ActionsButtons/ActionSelection';
import styles from './CustomTable.module.scss';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { getCookies } from '../../hooks/useCookies';
import { REACT_APP_API_FETCH_TICKET_STATUS } from '../../redux/apiConstants';

function CustomTable({ data, viewPopUp, toggleButton, section, deleteCondition }) {
  console.log(data);

  const { dashboardStyle } = useContext(TableContext);
  const navigate = useNavigate();
  const { bodyStyle } = useContext(TableContext);
  const [addButtonPermissions, setAddButtonPermissions] = useState({});
  const { bodyheader } = useContext(TableContext);
  const { setDashboardHeader } = useContext(TableContext);
  const { searchboxStyle } = useContext(TableContext);
  const { tableheadStyle } = useContext(TableContext);
  const { tablebodyStyle } = useContext(TableContext);
  const [tableData, setTableData] = useState([]);
  const [ticketStatus, setticketStatus] = useState([]);
  const dispatch = useDispatch();
  const {
    selected,
    setselected,
    columns,
    tableLoading,
    setPageNum,
    paginationData,
    sorting,
    setSorting,
    setSearchTerm,
    setPaginationData,
    setColumns,
    deletedData,
    setDeletedData,
    setFilterTerm,
  } = useContext(TableContext);
  // new

  const tablelength = tableData.length;
  const [checkedState, setCheckedState] = useState(new Array(tablelength).fill(false));
  const [checkedAll, setCheckedAll] = useState(false);
  const handleOnChange = (id, position) => {
    // eslint-disable-next-line max-len
    // eslint-disable-next-line max-len
    const updatedCheckedState = checkedState.map((item, indexed) =>
      indexed === position ? !item : item
    );
    setCheckedState(updatedCheckedState);
    setselected((oldArray) => [...oldArray, id]);
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i <= checkedState.length; i++) {
      if (checkedState[position] === true) {
        // eslint-disable-next-line no-plusplus
        for (let j = 0; j < selected.length; j++) {
          if (selected[j] === id) {
            selected.splice(j, 1);
          }
          // const newselected = selected.filter((item) => item === id);
          // setselected(newselected);
        }
      }
    }
  };
  const actionAdd = () => {
    navigate(`/${section.toLowerCase()}/create/`);
  };
  const handleOnChangeAll = () => {
    setCheckedAll(!checkedAll);
    if (checkedAll === false) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i <= tableData.length; i++) {
        const newarray = [];
        newarray.push(tableData[i].id);
        checkedState[i] = true;
        setselected((oldArray) => [...oldArray, [newarray]]);
      }
    } else {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i <= tableData.length; i++) {
        checkedState[i] = false;
        setselected([]);
      }
    }
  };
  useEffect(() => {
    // setDashboardHeader(section);
    const temp = section.replace(/\s+/g, '').toLowerCase();
    const permissions = getCookies('USERPERMISSION');
    permissions.forEach((val) => {
      if (val.section.toLowerCase() === temp) {
        setAddButtonPermissions({
          view: val.view ? val.view : false,
          edit: val.edit ? val.edit : false,
          create: val.create ? val.create : false,
          delete: val.delete ? val.delete : false,
          list: !!(val.view || val.edit || val.create || val.delete),
        });
      }
    });
    setTableData(data.results);
    setPaginationData({
      page: data.page,
      totalResult: data.totalResults,
      totalPages: data.totalPages,
    });
    setColumns(data.Displayfields);
  }, [data, deletedData]);

  const TicketStatus = async () => {
    try {
      const result = await axios.get(`${REACT_APP_API_FETCH_TICKET_STATUS}`);
      console.log(result, 'r');
      setticketStatus(result.data.data.results);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    TicketStatus();
  }, []);
  // const getTicketByStatus = async (status) => {
  //   console.log(status);
  //   try {
  //     if (status === 'All') {
  //       setTableData(data.results);
  //     } else {
  //       const res = await axios.get(`http://localhost:4036/v1/ticket/status/${status}`);
  //       console.log(res);
  //       setTableData(res.data.data.results);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  const setSort = (param) => () => {
    setPageNum(1);
    switch (sorting.sortType) {
      case 'none':
        setSorting({ col: param, sortType: 'asc' });
        break;
      case 'asc':
        setSorting({ col: param, sortType: 'desc' });
        break;
      case 'desc':
        setSorting({ col: param, sortType: 'none' });
        break;
      default:
        setSorting({ col: param, sortType: 'asc' });
        break;
    }
  };
  const onFilterTextChange = (e) => {
    const { value } = e.target;
    console.log(value);
    if (value.length > 0) {
      setSearchTerm(e.target.value);
    } else {
      setSearchTerm(null);
    }
  };

  const onFilterStatusChange = (e) => {
    console.log(e, 'e');
    const value = e;
    console.log(value);
    if (value === 'all') {
      setFilterTerm(null);
    } else if (value.length > 0) {
      setFilterTerm(value);
    } else {
      setFilterTerm(null);
    }
  };
  return (
    <div className={styles[bodyStyle]}>
      {addButtonPermissions.list && (
        <div
          className={styles[dashboardStyle]}
          style={{ minHeight: '100vh' }}
          id={styles.dashboardcont2}
        >
          <div className="container-fluid" id={styles.container}>
            <div className="row">
              <div className="col-lg-4 col-md-12 col-sm-12">
                <input
                  className={styles[searchboxStyle]}
                  id={styles.searchbar}
                  type="search"
                  onChange={onFilterTextChange}
                  placeholder="Search"
                />
                {/* <h5 className={styles[bodyheader]} id={styles.tableheading}>
                  {section} Data
                </h5> */}
              </div>
              <div className="col-lg-3 col-md-12 col-sm-12" />
              <div className="col-lg-5 col-md-12 col-sm-12">
                <div className={styles.sectionright}>
                  {data.Displayfields[1] === 'userName' ? (
                    <div>
                      <label htmlFor="hh" className="labels">
                        Status{' '}
                      </label>

                      <select
                        onClick={(e) => onFilterStatusChange(e.target.value)}
                        className="form-control"
                      >
                        <option value="all">All</option>
                        {ticketStatus.map((status) => (
                          <option value={status.status}>{status.status}</option>
                        ))}
                      </select>
                    </div>
                  ) : (
                    <div>
                      {addButtonPermissions.delete && (
                        <button
                          className={styles.tabledelbtn}
                          type="button"
                          onClick={() => {
                            confirmAlert({
                              title: 'Confirm to delete',
                              message: 'Are you sure want to delete.',
                              buttons: [
                                {
                                  label: 'Yes',
                                  onClick: () => {
                                    dispatch(tabledelete(selected)).then(() => {
                                      setDeletedData(`data deleted ${selected}`);
                                      window.location.reload(false);
                                    });
                                  },
                                },
                                {
                                  label: 'No',
                                  // onClick: () => alert("Click No")
                                },
                              ],
                            });
                          }}
                        >
                          <i className="fa fa-trash" aria-hidden="true" id={styles.del_icon} />
                        </button>
                      )}
                      {addButtonPermissions.create && (
                        <button
                          className={styles.tablebtn}
                          type="button"
                          onClick={() => {
                            actionAdd();
                          }}
                        >
                          {' '}
                          <FontAwesomeIcon icon={faPlus} className={styles.add_icon} />
                          Add
                        </button>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="table-responsive" style={{ minHeight: '250px' }}>
              <table className="table table-borderless">
                {columns.length > 0 && (
                  <thead>
                    <tr className={styles[tableheadStyle]}>
                      {addButtonPermissions.delete && (
                        <th scope="col">
                          <input
                            type="checkbox"
                            id="custom-checkbox"
                            name={data.id}
                            value={data.id}
                            checked={checkedAll}
                            onChange={() => handleOnChangeAll()}
                          />
                        </th>
                      )}
                      {columns.map((item) => (
                        <th scope="col">
                          {' '}
                          {item.toUpperCase()}
                          <button
                            className={styles.sortbtn}
                            type="button"
                            aria-label="Sort button"
                            onClick={setSort(item)}
                          >
                            {sorting.sortType === 'asc' && sorting.col === item ? (
                              <i className="fa fa-arrow-up" />
                            ) : sorting.sortType === 'desc' && sorting.col === item ? (
                              <i className="fa fa-arrow-down" />
                            ) : (
                              <i className="fa fa-sort" />
                            )}
                          </button>
                        </th>
                      ))}
                      <th scope="col">ACTIONS</th>
                    </tr>
                  </thead>
                )}
                {Object.keys(tableData) || !tableLoading ? (
                  <tbody>
                    {tableData.map((item, index) => (
                      <tr className={styles[tablebodyStyle]}>
                        {addButtonPermissions.delete && (
                          <th scope="row">
                            <div className="left-section">
                              <input
                                type="checkbox"
                                id={`custom-checkbox-${item}`}
                                name={data.id}
                                value={data.id}
                                checked={checkedState[index]}
                                onChange={() => handleOnChange(item.id, index)}
                              />
                              <label htmlFor={`custom-checkbox-${item}`}>{data.id}</label>
                            </div>
                          </th>
                        )}
                        {columns.map((ele) => (
                          <td>
                            {ele === toggleButton.field ? (
                              <ToggleButtonOnOff
                                value={item[ele]}
                                id={item.id}
                                placeholders={['Active', 'Inactive']}
                              />
                            ) : typeof item[ele] === 'boolean' ? (
                              <h6 className="order_date"> String(item[ele])</h6>
                            ) : (
                              item[ele]
                            )}
                          </td>
                        ))}
                        <td>
                          <ActionButtons data={item} viewPopUp={viewPopUp} section={section} />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                ) : (
                  <h6>Loading...</h6>
                )}
              </table>
            </div>
            <Pagination paginationData={paginationData} setPageNum={setPageNum} />
            <br />
            <br />
          </div>
        </div>
      )}
    </div>
  );
}
CustomTable.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  viewPopUp: PropTypes.bool,
  toggleButton: PropTypes.oneOfType([PropTypes.object]),
  deleteCondition: PropTypes.oneOfType([PropTypes.object]),
  section: PropTypes.string,
};
CustomTable.defaultProps = {
  viewPopUp: false,
  toggleButton: { show: false, field: 'none' },
  deleteCondition: { checkCondition: false, apiUrl: 'none' },
  section: 'User',
};
export default CustomTable;
