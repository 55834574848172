import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { toast, ToastContainer } from 'react-toastify';
import { SpinnerDotted } from 'spinners-react';
import { REACT_APP_API_FETCH_TICKET_STATUS } from '../../redux/apiConstants';
import { TableContext } from '../../contexts/tableContext';
import styles from './index.module.scss';
import Edit from './edit';
// import music from '../../assets/audio/Evillaugh.ogg';

function index() {
  const { setDashboardHeader, dashboardStyle, bodyStyle } = useContext(TableContext);
  setDashboardHeader('Ticket Status');
  const [ticketStatus, setticketStatus] = useState([]);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const ticketStatuses = async () => {
    try {
      const res = await axios.get(`${REACT_APP_API_FETCH_TICKET_STATUS}`);
      console.log(res, 'res');
      setticketStatus(res.data.data.results);
    } catch (error) {
      console.log(error);
    }
  };
  const formSchema = Yup.object().shape({
    status: Yup.string().required(' Status is mendatory'),
  });

  const formOptions = { resolver: yupResolver(formSchema) };
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    trigger,
  } = useForm(formOptions);

  const onSubmit = async (res) => {
    const data = {
      status: res.status,
    };
    console.log(data);
    reset();

    try {
      const response = await axios.post(`${REACT_APP_API_FETCH_TICKET_STATUS}`, data);
      console.log('res', response);
      handleClose();
      toast(response.data.context, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      reset();
      ticketStatuses();
      //   alldatas();
    } catch (error) {
      console.log('err', error);
      //   toast(error.response.data.message, {
      //     position: 'top-right',
      //     autoClose: 5000,
      //     hideProgressBar: false,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     draggable: true,
      //     progress: undefined,
      //     theme: 'dark',
      //   });
    }
  };
  useEffect(() => {
    ticketStatuses();
  }, []);
  console.log(ticketStatus);

  //   const audio = new Audio(music);

  //   const togglePlay = () => {
  //     console.log(audio);
  //     if (audio !== undefined) {
  //       audio.play();
  //     }
  //   };
  //   const togglePause = () => {
  //     console.log(audio);
  //     if (audio !== undefined) {
  //       audio.pause();
  //     }
  //   };
  return (
    <div className={styles[bodyStyle]}>
      <div
        className={styles[dashboardStyle]}
        style={{ height: '100vh' }}
        id={styles.dashboardcont2}
      >
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        {/* Same as */}
        <ToastContainer />
        <div className="d-flex justify-content-between mt-3">
          <p> </p>
          {/* <span>
            <button type="button" className="btn btn-primary" onClick={togglePlay}>
              play
            </button>
            <button type="button" className="btn btn-primary" onClick={togglePause}>
              pause
            </button>
          </span> */}
          <button type="button" className="btn btn-primary" onClick={handleShow}>
            Add
          </button>
          <Modal show={show} onHide={handleClose}>
            <form className="comment-form" method="post" onSubmit={handleSubmit(onSubmit)}>
              <Modal.Header closeButton>
                <Modal.Title>Create </Modal.Title>
              </Modal.Header>
              <div className="comment-respond container">
                <p className="comment-form-url">
                  <label htmlFor="department">Status</label>
                </p>
                <input
                  type="text"
                  id="status"
                  name="status"
                  className="form-control"
                  required
                  /* eslint-disable react/jsx-props-no-spreading */
                  {...register('status')}
                  onKeyUp={() => {
                    trigger('status');
                  }}
                />
                {errors.status && <small style={{ color: 'red' }}>{errors.status.message}</small>}
              </div>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
                <Button variant="primary" type="submit" onClick={handleSubmit(onSubmit)}>
                  Save Changes
                </Button>
              </Modal.Footer>
            </form>
          </Modal>
        </div>
        {Object.keys(ticketStatus).length > 0 ? (
          <table className="table border mt-3">
            <thead className="thead-dark">
              <tr>
                <th scope="col">index</th>
                <th scope="col">Status</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {ticketStatus.map((value, no) => (
                /* eslint no-underscore-dangle: 0 */
                <tr>
                  <th scope="row">{no + 1}</th>
                  <td>{value.status}</td>

                  <td>
                    {' '}
                    <Edit id={value._id} datas={ticketStatuses} />{' '}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <SpinnerDotted style={{ marginLeft: '400px', marginTop: '200px', color: '#39979d' }} />
        )}
      </div>
    </div>
  );
}

export default index;
