import axios from 'axios';
import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import {
  REACT_APP_API_DELETE_TICKET_STATUS,
  REACT_APP_API_EDIT_TICKET_STATUS,
  REACT_APP_API_ONE_TICKET_STATUS,
} from '../../redux/apiConstants';
import styles from './edit.module.scss';

function edit({ id, datas }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const [status, setstatus] = useState();

  const [Id, setId] = useState();
  console.log(Id);
  /* eslint no-underscore-dangle: 0 */
  const handleShow = async (ID) => {
    console.log(ID);

    try {
      const response = await axios.get(`${REACT_APP_API_ONE_TICKET_STATUS}/${ID}`);
      console.log(response, 'res');
      setId(response.data.data.Status._id);
      setstatus(response.data.data.Status.status);

      setShow(true);
    } catch (error) {
      console.log(error);
      setShow(true);
      // toast(error.response, {
      //   position: "top-right",
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      // });
    }
  };

  // edit

  const Edit = async (e) => {
    e.preventDefault();
    try {
      const data = {
        status,
      };
      const response = await axios.patch(`${REACT_APP_API_EDIT_TICKET_STATUS}/${Id}`, data);
      console.log(response);
      toast(response.data.context, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      datas();
      handleClose();
    } catch (error) {
      console.log(error);
      handleClose();
    }
  };

  // delete

  const Delete = async (idd) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await axios.delete(`${REACT_APP_API_DELETE_TICKET_STATUS}/${idd}`);
          console.log(response);
          Swal.fire('Deleted!', response.data.context, 'success');
          datas();
        } catch (error) {
          console.log(error);
        }
      }
    });
  };

  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* Same as */}
      <ToastContainer />
      <Modal show={show} onHide={handleClose}>
        <form className="comment-form" method="post">
          <Modal.Header closeButton>
            <Modal.Title>Edit </Modal.Title>
          </Modal.Header>
          <div className="comment-respond container">
            <p className="comment-form-url">
              <label htmlFor="question">Status</label>
            </p>
            <input
              type="text"
              id="question"
              name="question"
              className="form-control"
              value={status}
              onChange={(e) => setstatus(e.target.value)}
            />
          </div>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type="button" onClick={Edit}>
              Save Changes
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
      <span>
        <button type="button" className={styles.actionbtn} onClick={() => handleShow(id)}>
          <i className="fa-solid fa-pen-to-square" />
        </button>
        <button type="button" className={styles.actionbtn} onClick={() => Delete(id)}>
          <i className="fa-solid fa-trash" />
        </button>
      </span>
    </div>
  );
}

export default edit;
