import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import { SpinnerDotted } from 'spinners-react';
import { TableContext } from '../../contexts/tableContext';
import styles from './index.module.scss';
import './details.css';
import { REACT_APP_API_FETCH_KNOWLEDGE_BASE_DETAILS } from '../../redux/apiConstants';

function details() {
  const { dashboardStyle, bodyStyle, setDashboardHeader } = useContext(TableContext);
  const { state } = useLocation();
  console.log('state', state);
  const id = state.knowledge_base_id;
  console.log('id', id);
  const [knowledgeBase, setknowledgeBase] = useState({});
  const submit = async () => {
    try {
      const response = await axios.get(`${REACT_APP_API_FETCH_KNOWLEDGE_BASE_DETAILS}/${id}`);
      console.log(response, 'res');
      setknowledgeBase(response.data.data.knowledgeBase);
    } catch (error) {
      console.log(error);
      // toast(error.response, {
      //   position: "top-right",
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      // });
    }
  };
  useEffect(() => {
    setDashboardHeader('Knowledge Base Details');
    submit();
  }, [id]);
  return (
    <div className={styles[bodyStyle]}>
      <div
        className={styles[dashboardStyle]}
        style={{ height: '100vh' }}
        id={styles.dashboardcont2}
      >
        {Object.keys(knowledgeBase).length > 0 ? (
          <div className="blog-single gray-bg">
            <div className="container">
              <div className="row align-items-start">
                <div className="col-lg-8 m-15px-tb">
                  <article className="article">
                    <div className="article-title">
                      <div className="media">
                        <div className="avatar">
                          <img
                            src="https://bootdey.com/img/Content/avatar/avatar1.png"
                            title=""
                            alt=""
                          />
                        </div>
                        <div className="media-body">
                          <p>{knowledgeBase.userName}</p>
                          <span>
                            {moment(knowledgeBase.createdAt).format('MMMM Do YYYY, h:mm:ss a')}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="article-content">
                      <h4>{knowledgeBase.question}</h4>

                      <p>{knowledgeBase.answer}</p>
                      <div className="article-img">
                        <img
                          src="https://via.placeholder.com/800x350/87CEFA/000000"
                          title=""
                          alt=""
                        />
                      </div>
                    </div>
                  </article>
                  {/* <div className="contact-form article-comment">
                    <h4>Leave a Reply</h4>
                    <form id="contact-form" method="POST">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <input
                              name="Name"
                              id="name"
                              placeholder="Name *"
                              className="form-control"
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <input
                              name="Email"
                              id="email"
                              placeholder="Email *"
                              className="form-control"
                              type="email"
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <textarea
                              name="message"
                              id="message"
                              placeholder="Your message *"
                              rows={4}
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="send">
                            <button type="button" className="px-btn theme">
                              <span>Submit</span> <i className="arrow" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div> */}
                </div>
                <div className="col-lg-4 m-15px-tb blog-aside">
                  {/* Author */}
                  <div className="widget widget-author">
                    <div className="widget-title">
                      <h3>Author</h3>
                    </div>
                    <div className="widget-body">
                      <div className="media align-items-center">
                        <div className="avatar">
                          <img
                            src="https://bootdey.com/img/Content/avatar/avatar6.png"
                            title=""
                            alt=""
                          />
                        </div>
                        <div className="media-body">
                          <h6>
                            Hello, Iam
                            <br /> {knowledgeBase.userName}
                          </h6>
                        </div>
                      </div>
                      <p>
                        I design and develop services for customers of all sizes, specializing in
                        creating stylish, modern websites, web services and online stores
                      </p>
                    </div>
                  </div>
                  {/* End Author */}
                  {/* Trending Post */}
                  {/* <div className="widget widget-post">
                  <div className="widget-title">
                    <h3>Trending Now</h3>
                  </div>
                  <div className="widget-body" />
                </div> */}
                  {/* End Trending Post */}
                  {/* Latest Post */}
                  {/* <div className="widget widget-latest-post">
                  <div className="widget-title">
                    <h3>Latest Post</h3>
                  </div>
                  <div className="widget-body">
                    <div className="latest-post-aside media">
                      <div className="lpa-left media-body">
                        <div className="lpa-title">
                          <h5>
                            <a href="/login">Prevent 75% of visitors from google analytics</a>
                          </h5>
                        </div>
                        <div className="lpa-meta">
                          <a className="name" href="/login">
                            Rachel Roth
                          </a>
                          <a className="date" href="/login">
                            26 FEB 2020
                          </a>
                        </div>
                      </div>
                      <div className="lpa-right">
                        <a href="/login">
                          <img
                            src="https://via.placeholder.com/400x200/FFB6C1/000000"
                            title=""
                            alt=""
                          />
                        </a>
                      </div>
                    </div>
                    <div className="latest-post-aside media">
                      <div className="lpa-left media-body">
                        <div className="lpa-title">
                          <h5>
                            <a href="/login">Prevent 75% of visitors from google analytics</a>
                          </h5>
                        </div>
                        <div className="lpa-meta">
                          <a className="name" href="/login">
                            Rachel Roth
                          </a>
                          <a className="date" href="/login">
                            26 FEB 2020
                          </a>
                        </div>
                      </div>
                      <div className="lpa-right">
                        <a href="/login">
                          <img
                            src="https://via.placeholder.com/400x200/FFB6C1/000000"
                            title=""
                            alt=""
                          />
                        </a>
                      </div>
                    </div>
                    <div className="latest-post-aside media">
                      <div className="lpa-left media-body">
                        <div className="lpa-title">
                          <h5>
                            <a href="/login">Prevent 75% of visitors from google analytics</a>
                          </h5>
                        </div>
                        <div className="lpa-meta">
                          <a className="name" href="/login">
                            Rachel Roth
                          </a>
                          <a className="date" href="/login">
                            26 FEB 2020
                          </a>
                        </div>
                      </div>
                      <div className="lpa-right">
                        <a href="/login">
                          <img
                            src="https://via.placeholder.com/400x200/FFB6C1/000000"
                            title=""
                            alt=""
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div> */}
                  {/* End Latest Post */}
                  {/* widget Tags */}
                  {/* <div className="widget widget-tags">
                  <div className="widget-title">
                    <h3>Latest Tags</h3>
                  </div>
                  <div className="widget-body">
                    <div className="nav tag-cloud">
                      <a href="/login">Design</a>
                      <a href="/login">Development</a>
                      <a href="/login">Travel</a>
                      <a href="/login">Web Design</a>
                      <a href="/login">Marketing</a>
                      <a href="/login">Research</a>
                      <a href="/login">Managment</a>
                    </div>
                  </div>
                </div> */}
                  {/* End widget Tags */}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <SpinnerDotted style={{ marginLeft: '500px', marginTop: '200px', color: '#39979d' }} />
        )}
      </div>
    </div>
  );
}

export default details;
