import React, { useContext } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from 'reactstrap';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TableContext } from '../../contexts/tableContext';
/* eslint-disable react/prop-types */
function UserPasswordChange({ userID }) {
  console.log(userID, 'user');
  const { modal, setModal } = useContext(TableContext);
  const toggle = () => setModal(!modal);
  // update password
  const formSchema = Yup.object().shape({
    password: Yup.string().required(' Password is mendatory'),
    newpassword: Yup.string()
      .required(' New Password is mendatory')
      .matches(
        // eslint-disable-next-line
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
      ),
  });

  const formOptions = { resolver: yupResolver(formSchema) };
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    trigger,
  } = useForm(formOptions);

  const onSubmit = async (res) => {
    const data = {
      password: res.password,
      newpassword: res.newpassword,
    };
    try {
      const response = await axios.patch(`http://localhost:4036/v1/user/update/${userID}`, data);
      console.log(response, 'pass');
      reset();
      toast(response.data.context, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      setModal(false);
    } catch (error) {
      console.log(error.response.data.message, 'errrr');
      reset();
      toast(error.response.data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  };
  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Change Password</ModalHeader>
        <ModalBody>
          <form method="post" onSubmit={handleSubmit(onSubmit)}>
            <div className="row mb-3">
              <div className="col-sm-3">
                <h6 className="mb-0">Password</h6>
              </div>
              <div className="col-sm-9 text-secondary">
                <input
                  type="password"
                  className="form-control"
                  name="password"
                  placeholder="*******"
                  required
                  // eslint-disable-next-line
                  {...register('password')}
                  onKeyUp={() => {
                    trigger('password');
                  }}
                />
                {errors.password && (
                  <small className="text-danger">{errors.password.message}</small>
                )}
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-sm-3">
                <h6 className="mb-0">New Password</h6>
              </div>
              <div className="col-sm-9 text-secondary">
                <input
                  type="password"
                  className="form-control"
                  name="newpassword"
                  required
                  // eslint-disable-next-line
                  {...register('newpassword')}
                  onKeyUp={() => {
                    trigger('newpassword');
                  }}
                />
                {errors.newpassword && (
                  <small className="text-danger">{errors.newpassword.message}</small>
                )}
              </div>
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSubmit(onSubmit)}>
            Submit
          </Button>{' '}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default UserPasswordChange;
