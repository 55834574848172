import axios from 'axios';
import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  REACT_APP_API_FETCH_KNOWLEDGE_BASE_DETAILS,
  REACT_APP_API_EDIT_KNOWLEDGE_BASE_DETAILS,
} from '../../redux/apiConstants';
import styles from './edit.module.scss';

function edit({ source, datas }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const [question, setquestion] = useState();
  const [answer, setanswer] = useState();
  const [Id, setId] = useState();
  /* eslint no-underscore-dangle: 0 */
  const handleShow = async (id) => {
    console.log(id);

    try {
      const response = await axios.get(`${REACT_APP_API_FETCH_KNOWLEDGE_BASE_DETAILS}/${id}`);
      console.log(response, 'res');
      setId(response.data.data.knowledgeBase._id);
      setquestion(response.data.data.knowledgeBase.question);
      setanswer(response.data.data.knowledgeBase.answer);
      setShow(true);
    } catch (error) {
      console.log(error);
      setShow(true);
      // toast(error.response, {
      //   position: "top-right",
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      // });
    }
  };

  // edit

  const Edit = async (e) => {
    e.preventDefault();
    try {
      const data = {
        question,
        answer,
      };
      const response = await axios.patch(
        `${REACT_APP_API_EDIT_KNOWLEDGE_BASE_DETAILS}/${Id}`,
        data
      );
      console.log(response);
      toast(response.data.context, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      datas();
      handleClose();
    } catch (error) {
      console.log(error);
      handleClose();
    }
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* Same as */}
      <ToastContainer />
      <button className={styles.actionbtn} type="button" onClick={() => handleShow(source)}>
        <i className="fa-solid fa-pen-to-square" />
      </button>
      <Modal show={show} onHide={handleClose}>
        <form className="comment-form" method="post">
          <Modal.Header closeButton>
            <Modal.Title>Edit </Modal.Title>
          </Modal.Header>
          <div className="comment-respond container">
            <p className="comment-form-url">
              <label htmlFor="question">Question</label>
            </p>
            <input
              type="text"
              id="question"
              name="question"
              className="form-control"
              value={question}
              onChange={(e) => setquestion(e.target.value)}
            />

            <p className="comment-form-answer">
              <label htmlFor="answer">Answer</label>
            </p>

            <textarea
              className="form-control"
              name="answer"
              rows="4"
              cols="50"
              value={answer}
              onChange={(e) => setanswer(e.target.value)}
            />
          </div>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type="button" onClick={Edit}>
              Save Changes
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}

export default edit;
