import envValues from '../enviornment';

export const SERVER_URL = `${envValues.REACT_APP_API_ENDPOINT}/auth`;
export const SERVER_BASE_URL = envValues.REACT_APP_API_ENDPOINT;
export const ERROR_MESSAGE = 'Api Fetch Error!';
export const ENCRYPT_SECERET_KEY = 'armia-secret-key@123';
export const REACT_APP_API_POST_USER = `${envValues.REACT_APP_API_ENDPOINT}/user`;
export const REACT_APP_API_FETCH_USER = `${envValues.REACT_APP_API_ENDPOINT}/user?`;
export const REACT_APP_API_FETCH_EMPLOYEE = `${envValues.REACT_APP_API_ENDPOINT}/employee?active=true&`;
export const REACT_APP_API_FETCH_ROLE = `${envValues.REACT_APP_API_ENDPOINT}/roles?active=true&`;
export const REACT_APP_API_SKILLS = `${envValues.REACT_APP_API_ENDPOINT}/dummy/selectdata`;
export const REACT_APP_API_ROLES_DROPDOWN = `${envValues.REACT_APP_API_ENDPOINT}/roles/select`;
export const REACT_APP_API_FETCH_TICKETS = `${envValues.REACT_APP_API_ENDPOINT}/ticket?`;
export const REACT_APP_API_FETCH_TICKETDETAILS = `${envValues.REACT_APP_API_ENDPOINT}/ticket`;
export const REACT_APP_API_FETCH_TICKETREPLIES = `${envValues.REACT_APP_API_ENDPOINT}/ticketreply/all`;
export const REACT_APP_API_FETCH_POST_REPLY = `${envValues.REACT_APP_API_ENDPOINT}/ticketreply`;
export const REACT_APP_API_FETCH_KNOWLEDGE_BASE = `${envValues.REACT_APP_API_ENDPOINT}/knowledgebase?`;
export const REACT_APP_API_POST_KNOWLEDGE_BASE = `${envValues.REACT_APP_API_ENDPOINT}/knowledgebase`;
export const REACT_APP_API_FETCH_KNOWLEDGE_BASE_DETAILS = `${envValues.REACT_APP_API_ENDPOINT}/knowledgebase/`;
export const REACT_APP_API_EDIT_KNOWLEDGE_BASE_DETAILS = `${envValues.REACT_APP_API_ENDPOINT}/knowledgebase`;
export const REACT_APP_API_DELETE_KNOWLEDGE_BASE_DETAILS = `${envValues.REACT_APP_API_ENDPOINT}/knowledgebase`;
export const REACT_APP_API_FETCH_TICKET_DEPARTMENT = `${envValues.REACT_APP_API_ENDPOINT}/ticketdepartment/`;
export const REACT_APP_API_EDIT_TICKET_DEPARTMENT = `${envValues.REACT_APP_API_ENDPOINT}/ticketdepartment/`;
export const REACT_APP_API_ONE_TICKET_DEPARTMENT = `${envValues.REACT_APP_API_ENDPOINT}/ticketdepartment/`;
export const REACT_APP_API_DELETE_TICKET_DEPARTMENT = `${envValues.REACT_APP_API_ENDPOINT}/ticketdepartment/`;
export const REACT_APP_API_FETCH_TICKET_STATUS = `${envValues.REACT_APP_API_ENDPOINT}/ticketstatus`;
export const REACT_APP_API_ONE_TICKET_STATUS = `${envValues.REACT_APP_API_ENDPOINT}/ticketstatus/`;
export const REACT_APP_API_EDIT_TICKET_STATUS = `${envValues.REACT_APP_API_ENDPOINT}/ticketstatus/`;
export const REACT_APP_API_DELETE_TICKET_STATUS = `${envValues.REACT_APP_API_ENDPOINT}/ticketstatus/`;
export const REACT_APP_API_FETCH_TICKETS_LIST = `${envValues.REACT_APP_API_ENDPOINT}/dashboard/tickets`;
