import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { getCookies } from '../../hooks/useCookies';
import styles from './ToggleButton.module.scss';
import envValues from '../../enviornment';

function ToggleButtonOnOff({ value, id, placeholders }) {
  const [isOff, setIsOff] = useState(false);

  useEffect(() => {
    setIsOff(value);
  }, [value]);

  const handleButtonClick = () => {
    setIsOff(!isOff);
    const headers = {
      'Content-Type': 'application/json; charset=utf-8',
      'Access-Control-Allow-Origin': '*',
    };
    const body = { token: getCookies('Token'), userid: id, active: !isOff };
    axios
      .post(`${envValues.REACT_APP_API_ENDPOINT}/auth/toggle-change`, body, headers)
      .then(() => {});
  };
  return (
    <div className="col ">
      <button
        className={isOff ? styles.activebtn : styles.inactivebtn}
        type="button"
        onClick={handleButtonClick}
      >
        {isOff ? placeholders[0] : placeholders[1]}
      </button>
    </div>
  );
}

ToggleButtonOnOff.propTypes = {
  value: PropTypes.bool,
  placeholders: PropTypes.oneOfType([PropTypes.array]),
  id: PropTypes.string,
};
ToggleButtonOnOff.defaultProps = {
  value: false,
  placeholders: ['On', 'Off'],
  id: null,
};
export default ToggleButtonOnOff;
